@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('react-toastify/dist/ReactToastify.css');
@import url('../src/assets/css/main.css');

@layer base {
  @font-face {
    font-family: 'bebas';
    font-weight: 700;
    src: url('https://dev-play.flarie.com/font/v1/Bebas-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'bebas';
    font-weight: 400;
    src: url('https://dev-play.flarie.com/font/v1/Bebas-Regular.otf') format('opentype');
  }

  body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* @apply font-main; */
  }

  h2 {
    @apply text-2xl;
  }

  a {
    @apply text-primary underline;
  }

  .row {
    @apply -ml-2 -mr-2 flex flex-wrap justify-between;
  }

  #root {
    position: relative;
    height: 100vh;
    width: 100vw;
    margin: 0 auto;
    padding: 0;
    overflow-y: auto;
  }

  #root::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .btn-primary {
    @apply hover:bg-primary-deep bg-primary;
  }

  .section {
    @apply pt-6 pb-6;
  }

  .btn {
    @apply inline-flex items-center justify-center whitespace-nowrap rounded-[8px] no-underline shadow-sm transition-all;
  }

  .btn-lg {
    @apply h-14 min-w-[212px] pl-[10px] pr-[10px] text-xl;
  }

  .btn-md {
    @apply h-14 pl-2 pr-2 text-xl;
  }
}

img {
  max-width: 100%;
}