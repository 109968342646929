/* Bebas Neue + Poppins */
/* May be it need in future*/
/* @font-face {
  font-family: 'Bebas Neue';
  src: url('../../fonts/BebasNeue/BebasNeue-Regular.ttf');
} */

/* Museo Sans */
@font-face {
  font-family: 'Museo Sans thin';
  src: url('../../fonts/MuseoSans/MuseoSans-100.woff2');
}
@font-face {
  font-family: 'Museo Sans';
  src: url('../../fonts/MuseoSans/MuseoSans-300.woff2');
}
@font-face {
  font-family: 'Museo Sans medium';
  src: url('../../fonts/MuseoSans/MuseoSans_500.woff2');
}
@font-face {
  font-family: 'Museo Sans bold';
  src: url('../../fonts/MuseoSans/MuseoSans_700.woff2');
}
