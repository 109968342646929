/** We have to remove this file in future */
.custom-control input[type='checkbox'],
.custom-control input[type='radio'] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-control input:disabled+.custom-control-label {
  opacity: 0.6;
  cursor: no-drop;
}

.custom-control {
  position: relative;
  margin-bottom: 10px;
}

.custom-control .custom-control-label {
  position: relative;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 300;
}

.custom-control .custom-control-label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d4d6;
  border-radius: 1px;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-right: 7px;
  transition: 0.1s;
}

.custom-checkbox input:checked~.custom-control-label:after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 8px;
  width: 4px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked~.custom-control-label:before {
  background-color: #2a639b;
}

.custom-radio .custom-control-label:before {
  border-radius: 100%;
}

.custom-radio input:checked~.custom-control-label:before {
  border: 5px solid #2a639b;
  background-color: #fff;
}

.custom-radio .custom-control-label:hover:before {
  border: 5px solid #2a639b;
  background-color: #2a639b;
}

.custom-radio .custom-control-label:hover:after {
  top: 3px;
  left: 4px;
  width: 8px;
  height: 8px;
  border: solid #2a639b;
  border-width: 6px;
  transform: rotate(45deg);
  border-radius: 100%;
  display: none;
}