.shadow {
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.25));
}
.col-2 {
  padding-left: 5px;
  padding-right: 5px;
  width: calc(50% - 5px);
}
.col-1 {
  padding-left: 5px;
  padding-right: 5px;
}
.flarie-container {
  max-width: 500px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.shadow-md,
.shadow-xl {
  box-shadow: none !important;
}
.html-text-wrapper a {
  text-decoration: underline;
  color: #000;
}

.html-text-inherit-color a {
  color: inherit;
}
